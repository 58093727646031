<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        A gardener bought a package of 21 mixed seeds. The package contents listed:
      </p>
      <p class="mb-1">3 short red flowers</p>
      <p class="mb-1">4 short yellow flowers</p>
      <p class="mb-1">5 short orange flowers</p>
      <p class="mb-1">4 tall red flowers</p>
      <p class="mb-1">2 tall yellow flowers</p>
      <p class="mb-1">3 tall orange flowers</p>
      <p class="mb-2">
        If just one seed is planted, what are the chances that the plant that grows will have red
        flowers?
      </p>

      <p class="mb-4">
        <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options1"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>

      <h3><u>Reason</u></h3>
      <p class="mb-2">
        <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 ml-3">
          <v-radio
            v-for="option in options2"
            :key="option.value"
            :value="option.value"
            class="my-3"
          >
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </v-radio-group>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'UPEIToTLItem6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options1: [
        {text: '1 out of 2', value: 'a'},
        {text: '1 out of 3', value: 'b'},
        {text: '1 out of 7', value: 'c'},
        {text: '1 out of 21', value: 'd'},
        {text: 'Other', value: 'e'},
      ],
      options2: [
        {
          text: 'One seed has to be chosen from among those that grow red, yellow or orange flowers.',
          value: 'one',
        },
        {
          text: '1/4 of the short and 4/9 of the talls are red.',
          value: 'two',
        },
        {
          text: 'It does not matter whether a tall or a short is picked. One red seed needs to be picked from a total of seven red seeds.',
          value: 'three',
        },
        {
          text: 'One red seed must be selected from a total of 21 seeds.',
          value: 'four',
        },
        {
          text: 'Seven of the twenty one seeds will produce red flowers.',
          value: 'five',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
